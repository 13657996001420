import React from 'react';
import './location.css'
import Iframe from 'react-iframe'
import { useParams } from 'react-router-dom';

function Location() {

  const {language='ru'} = useParams()


  return (
    <>
    <section id="location">
        <h2>{(language=='ru' ? 'местоположение' : language=='uz' ? 'joylashuv' : 'location')}</h2>
        <div className="location__map">
        <Iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1782.3489039595522!2d69.28711412017039!3d41.297089280310566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef585b1797cf7%3A0x59c5415fe0bae0a7!2sccslife!5e0!3m2!1sru!2s!4v1651079713177!5m2!1sru!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></Iframe>
        </div>  
    </section>
    </>
  );
}

export default Location;
