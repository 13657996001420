/* This component for texts of languages  */

/* common texts */
export const readMore = [
  {
    ru: "Подробнее",
  },
  {
    uz: "Batafsil",
  },
  {
    en: "Read more...",
  },
];
/* /common texts */

/* Header */
export const headerApplication = [
  {
    ru: "Оставить заявку",
  },
  {
    uz: "Ariza qoldirish",
  },
  {
    en: "Application",
  },
];
/* /Header */

/* NavBar */
export const navbarHome = [
  {
    ru: "Главная",
  },
  {
    uz: "Asosiy",
  },
  {
    en: "Home",
  },
];

export const navbarAbout = [
  {
    ru: "О нас",
  },
  {
    uz: "Biz haq.",
  },
  {
    en: "About us",
  },
];

export const navbarCatalog = [
  {
    ru: "Каталог",
  },
  {
    uz: "Katalog",
  },
  {
    en: "Catalogs",
  },
];

export const navbarProjects = [
  {
    ru: "Проекты",
  },
  {
    uz: "Proektlar",
  },
  {
    en: "Projects",
  },
];

export const navbarContacts = [
  {
    ru: "Контакты",
  },
  {
    uz: "Aloqa",
  },
  {
    en: "Contacts",
  },
];
/* /Navbar */

/* Catalogs */
export const catalogHeader = [
  {
    ru: "КАТАЛОГ ТОВАРОВ",
  },
  {
    uz: "Maxsulotlar Katalogi",
  },
  {
    en: "CATALOG",
  },
];

export const catalogContent = [
  {
    ru: `У нас вы найдете огромный выбор строителных материалов и специального промышленного оборудования:
        системы вентилатции, кондиционирования, отопления, охлаждения, пожарную систему и запчасти.`,
  },
  {
    uz: `Bu yerda siz qurilish materiallari va maxsus sanoat uskunalarining katta tanlovini topasiz:
        ventilyatsiya, konditsioner, isitish, sovutish tizimlari, yong'inga qarshi tizim va ehtiyot qismlar.`,
  },
  {
    en: `Here you will find a huge selection of building materials and special industrial equipment:
        ventilation, air conditioning, heating, cooling systems, fire system and spare parts.`,
  },
];

/* Categories */
export const categoriesHeader = [
  {
    ru: "КАТАЛОГ ТОВАРОВ ИЗ Все категории",
  },
  {
    uz: "Barcha kategoriyalar bo’yicha maxsulotlar",
  },
  {
    en: "CATALOG OF PRODUCTS FROM All categories",
  },
];

export const categoriesContent = [
  {
    ru: `У нас вы найдете огромный выбор строителных материалов и специального промышленного оборудования:
        системы вентилатции, кондиционирования, отопления, охлаждения, пожарную систему и запчасти.`,
  },
  {
    uz: `Bu yerda siz qurilish materiallari va maxsus sanoat uskunalarining katta tanlovini topasiz:
        ventilyatsiya, konditsioner, isitish, sovutish tizimlari, yong'inga qarshi tizim va ehtiyot qismlar.`,
  },
  {
    en: `The silencer is used to absorb the noise generated during the operation of ventilation equipment and
        propagating through the ducts of ventilation systems.`,
  },
];
/* /Categories */
/* /Catalogs */

/* Brands */
export const brandsContent = [
  {
    ru: `Компания << CCS Comfort Cooling Service>> работает с этим надежным 
        брендом с момента основания в 2000 году. Производитель предлагает 
        большой ассорт  имент продукции: от проводки и кабелей до тяжелых станков.`,
  },
  {
    uz: `<<CCS Comfort Cooling Service>> brendi 2000 yilda tashkil topganidan beri 
        yuqori sifat bilan ishlamoqda. Ishlab chiqaruvchi sizga
        mahsulotlarning keng 
        assortimentini taklif qiladi: simlar va kabellardan tortib og'ir mashinalargacha.`,
  },
  {
    en: `<<CCS Comfort Cooling Service>> works with this reliable brand since its founding in 2000. 
        The manufacturer offers a wide range of products: from wiring and cables to heavy machines.`,
  },
];
/* /Brands */

/* Connect */
export const connectHeader = [
  {
    ru: `СТРОЙТЕ  ВМЕСТЕ С КОМПАНИЕЙ 
        <<CCS COMFORT COOLING 
        SERVICE>> `,
  },
  {
    uz: `KOMPANIYANGIZNI <<CCS 
        COMFORT COOLING SERVICE>> 
        BILAN BIRGA QURING`,
  },
  {
    en: `BUILD YOUR COMPANY WITH  
        <<CCS COMFORT COOLING 
        SERVICE>> `,
  },
];
/* /Connect */

/* AboutPage */

export const AboutPageHeaderFirst = [
  {
    ru: `
        У Вас появилась уникальная Возможность приобрести качественные оборудование согласно вашим требованиям, а так же товары от ведущих мировых брендов, которые имеют сотни положительных отзывов, возможность получить рекомендации по установке техники и ее дальнейшей эксплуатации, отопительное и климатическое оборудование, тепло- и звукоизоляционные материалы и многое другое по приемлемым ценам от официального дистрибьютора с гарантийными обязательствами как от производителя, так и от компании.`,
  },
  {
    uz: `
        Sizning uyingiz uchun eng sifatli isitish qurilmalarini, dunyoning 100 lab ijobiy taqrizlarga ega yetakchi brendlaridan tovarlar,
        texnikani o’rnatish va undan foydalanish bo’yicha tavsiyalar olish imkoniyati rasmiy distributordan maqbul narxlarda isitish 
        texnikasi, issiqlik va tovushdan izolatsiyalovchi materiallar hamda ham ishlab chiqaruvchidan, ham kompaniyadan kafolat 
        majburiyatlari olish mumkin.`,
  },
  {
    en: `
        The opportunity to buy the highest quality heating devices for your home,
        goods from the world's leading brands that have hundreds of positive reviews,
        the opportunity to receive recommendations on the installation of equipment and its further operation,
        heating equipment, heat and sound insulation materials at affordable prices from the official
        distributor,
        warranties from both the manufacturer and the company.`,
  },
];

export const AboutPageHeaderSecond = [
  {
    ru: `
        Планируя использовать в своих нуждах тепло-холода производящую технику или насосные станции, звукоизоляционные материалы, комплектующие, а так же иной вид техники Вы имеете возможность найти все необходимое по самым доступным ценам именно у нас! Благодаря удобным условиям, отличному качеству продукции, и высокой репутации представленных брендов Вы можете быть уверены, что приобретаете лучшее из представленного на рынке не только РУ, а так-же всего мира.`,
  },
  {
    uz: `
        O’z uyingizda issiqlik yoki tovushdan izolatsiyaovchi materiallarni ishlatishni rejalashtirar ekansiz, sizz aynan bizda eng qulay 
        narxlarda barcha zarar narsalarni sotib olishingiz mumkin. Qulay sharoitlar maxsulotning a’lo sifati va tavsiya qilinayotgan 
        brendlarning obro’si tufayli siz bozorda mavjud maxsulotlarning eng yaxshisini sotib olayotganingizga bemalol ishonishingiz
        mumkin.`,
  },
  {
    en: `
        When planning to use heat or sound insulating materials in your home, you have the opportunity to buy
        We have everything you need at the most affordable prices! Thanks to convenient conditions, excellent quality
        products, and the high reputation of the represented brands, you can be sure that you are getting the best
        from those on the market.`,
  },
];

export const AboutPageHeaderThird = [
  {
    ru: `
        Квалифицированные консультанты в сочетании с высоким качеством товаров позволили нам создать довольно широкую клиентскую базу и регулярно получать массу положительных отзывов! Мы уверены, обратившись к нам, и Вы, непременно, оставите свой отзыв об удачном приобретении! `,
  },
  {
    uz: `
        Tovarlarning yuqori sifati bilan uyg’unlikda yuqori malakali konsultantlarning mavjudligi bizga mijozlarning keng bazasini
        yaratish va muntazam raavishda ko’plab ijobiy taqrizlar olish imkonini berdi. Biz ishonamizki bizga murojaat qilib siz 
        ham albatta omadli haridingiz haqida izoh qoldirasiz.`,
  },
  {
    en: `  
        Qualified consultants, combined with the high quality of goods, have allowed us to create quite
        wide customer base and regularly receive a lot of positive feedback! We are confident that by contacting us, and
        You will certainly leave your feedback about a successful purchase!`,
  },
];

export const AboutHistory = [
  {
    ru: `
        За годы работы компания «Сomfort Cooling Service» приобрела репутацию в Узбекистане. Основатель 
        компании Давлетов Азамат Аминович открыл свой бизнес в 2014 году, вложив в него огромный опыт 
        работы в строительной отрасли. Сегодня «Сomfort Cooling Service» является одним из ведущих 
        поставщиков высококачественных материалов и оборудования, а также предлагает полный спектр услуг 
        по самым конкурентоспособным ценам.
        
        `,
  },
  {
    uz: `
        O‘tgan yillar davomida “Comfort Cooling Service” O‘zbekistonda shuhrat qozondi. Korxona asoschisi 
        Davletov Azamat Aminovich 2014-yilda qurilish sohasida katta tajribaga sarmoya kiritib, o‘z biznesini ochgan.
        Bugungi kunda "Comfort Cooling Service" yuqori sifatli materiallar va jihozlarning yetakchi yetkazib 
        beruvchilaridan biri bo'lib, eng raqobatbardosh narxlarda to'liq xizmatlarni taklif etadi.
        `,
  },
  {
    en: `
        Over the years, Comfort Cooling Service has gained a reputation in Uzbekistan. Founder
        company Davletov Azamat Aminovich opened his own business in 2014, investing in it vast experience
        work in the construction industry. Today "Comfort Cooling Service" is one of the leading
        suppliers of high quality materials and equipment, and offers a full range of services
        at the most competitive prices. `,
  },
];

export const AboutQualityOne = [
  {
    ru: `Критерии качества, инноваций, индивидуальных решений, а также укрепление партнерских связей, постоянное улучшение 
        обслуживания, передовое мышление, стремление быть на шаг впереди конкурентов, осознание важности охраны окружающей 
        среды формируют четкую философию «Сomfort Cooling Service».
        
        Качество - это критерий, который рассматривается нашими клиентами, как основной, и является определяющим фактором 
        конкурентоспособности продукции Теплоклимат. В связи с этим действия рабочего персонала, оптимизация и оценка производства 
        на предприятии, в первую очередь направлены на повышение качества. «Сomfort Cooling Service» всегда с вниманием относится к 
        требованиям и пожеланиям наших партнеров.`,
  },
  {
    uz: ` "Comfort Cooling Service" ning sifat mezonlari, innovatsiyalar, individual yechimlar, hamkorlikni mustahkamlash, doimiy 
        takomillashtirish, xizmat ko'rsatish, innovatsion fikrlash, raqobatdan bir qadam oldinda bo'lish istagi, atrof-muhitni muhofaza 
        qilish muhimligini anglashni tashkil qiladi.`,
  },
  {
    en: `Criteria for quality, innovation, individual solutions, as well as strengthening partnerships, continuous improvement
        service, innovative thinking, the desire to be one step ahead of the competition, awareness of the importance of environmental protection
        environments form a clear philosophy of "Comfort Cooling Service".
        
        Quality is a criterion that is considered by our customers as the main one, and is the determining factor
        competitiveness of products Teploclimat. In this regard, the actions of the working personnel, optimization and evaluation of production
        at the enterprise, first of all, aimed at improving quality. "Comfort Cooling Service" always pays attention to
        requirements and wishes of our partners.`,
  },
];

export const AboutQualitySecond = [
  {
    ru: `
        Качество - это критерий, который рассматривается нашими клиентами, как основной, и является определяющим фактором 
        конкурентоспособности продукции Теплоклимат. В связи с этим действия рабочего персонала, оптимизация и оценка производства 
        на предприятии, в первую очередь направлены на повышение качества. «Сomfort Cooling Service» всегда с вниманием относится к 
        требованиям и пожеланиям наших партнеров.`,
  },
  {
    uz: ` `,
  },
  {
    en: `
        Quality is a criterion that is considered by our customers as the main one, and is the determining factor
        competitiveness of products Teploclimat. In this regard, the actions of the working personnel, optimization and evaluation of production
        at the enterprise, first of all, aimed at improving quality. "Comfort Cooling Service" always pays attention to
        requirements and wishes of our partners.`,
  },
];
/* /AboutPage */
