import React, { useEffect, useState } from "react";
import "./mainComp.css";
import Carousel from "react-bootstrap/Carousel";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Basic_Url } from "../BasicUrl";

function MainComp() {
  const { language = "ru" } = useParams();

  const [cover, setCover] = useState([]);

  useEffect(() => {
    getInfos();
  }, []);

  useEffect(() => {
    getInfos();
  }, [language]);

  async function getInfos() {
    const data = await axios
      .get(Basic_Url + "/api/" + (language ? language : "ru") + "/cover/")
      .then((res) => res.data);
    setCover(data);
  }

  return (
    <>
      <main>
        <Carousel>
          {cover.map((item) => {
            return (
              <Carousel.Item key={item.id}>
                <div className="image__box first-image__box"></div>
                <img
                  className="d-block w-100"
                  src={item.img}
                  alt="First slide"
                />
                <Carousel.Caption>
                  <h3>{item.text}</h3>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>
      </main>
    </>
  );
}

export default MainComp;
