import React, { useEffect, useState } from "react";
import "./footer.css";
import logo from "./Footer photos/CCS 6.png";
import {
  navbarAbout,
  navbarCatalog,
  navbarContacts,
  navbarHome,
  navbarProjects,
} from "../TextsLanguages";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Basic_Url } from "../BasicUrl";
import { Link, NavLink } from "react-router-dom";
import { Navbar, NavDropdown, Nav } from "react-bootstrap";

function Footer() {
  const { language = "ru" } = useParams();

  /* Texts Languages */
  const navbarHomeText = navbarHome.filter(
    (item) => Object.keys(item) == language
  );
  const navbarAboutText = navbarAbout.filter(
    (item) => Object.keys(item) == language
  );
  const navbarCatalogText = navbarCatalog.filter(
    (item) => Object.keys(item) == language
  );
  const navbarProjectsText = navbarProjects.filter(
    (item) => Object.keys(item) == language
  );
  const navbarContactsText = navbarContacts.filter(
    (item) => Object.keys(item) == language
  );
  /* /Texts Languages */

  const [infos, setInfos] = useState([]);

  useEffect(() => {
    getInfos();
  }, []);

  useEffect(() => {
    getInfos();
  }, [language]);

  async function getInfos() {
    const data = await axios
      .get(Basic_Url + "/api/" + (language ? language : "ru") + "/info/")
      .then((res) => res.data);
    setInfos(data[0]);
  }

  return (
    <>
      <footer>
        <div id="navbar_footer">
          <nav className="navbar navbar-expand-lg navbar-light ">
            <div className="container-fluid">
              <div className="logo">
                <a href="#">
                  <img src={logo} alt="logo" />
                </a>
              </div>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      aria-current="page"
                      to={"/" + (language ? language : "ru")}
                    >
                      {Object.values(navbarHomeText[0]).join()}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      aria-current="page"
                      to={"/" + (language ? language : "ru") + "/about"}
                    >
                      {Object.values(navbarAboutText[0]).join()}
                    </NavLink>
                  </li>
                  <li id="dropdown" className="nav-item">
                    <div className="dropdown">
                      <NavLink
                        to={"/" + (language ? language : "ru") + "/categories"}
                        className="dropdown"
                      >
                        {Object.values(navbarCatalogText[0]).join()}
                      </NavLink>

                      <div className="dropdown-menu">
                        <div
                          data-toggle="collapse"
                          data-target="#cardOne"
                        ></div>
                      </div>
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      aria-current="page"
                      to={"/" + (language ? language : "ru") + "/projects"}
                    >
                      {Object.values(navbarProjectsText[0]).join()}
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link active"
                      aria-current="page"
                      to={"/" + (language ? language : "ru") + "/contacts"}
                    >
                      {Object.values(navbarContactsText[0]).join()}
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="footer__logos">
                <a href="https://t.me/ccslife2020">
                  <i className="fa-brands fa-telegram"></i>
                </a>
                <a href="https://www.facebook.com/groups/1146734162825530">
                  <i className="fa-brands fa-facebook-f"></i>
                </a>
                <a href="https://instagram.com/ccslife.uz?igshid=YmMyMTA2M2Y=">
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </div>
            </div>
          </nav>
          <div className="footer__info">
            <div className="row">
              <div className="footer__info-item col-lg-6 col-md-6  col-sm-5">
                <div className="text-content">
                  <div className="row first_item">
                    <div className="col-md-12">
                      <div className="items d-flex">
                        <Link
                          to={"/" + (language ? language : "ru") + "/contacts"}
                        >
                          <i className="fa-solid fa-phone"></i>
                        </Link>
                        <div className="item__texts">
                          <p>
                            <a href={`tel:${infos.phone1}`}>{infos.phone1}</a>
                          </p>
                          <p>
                            <a href={`tel:${infos.phone2}`}>{infos.phone2}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row second_item">
                    <div className="col-md-12">
                      <div className="items d-flex">
                        <i className="fa-brands fa-react"></i>
                        <div className="item__texts">
                          <p>{infos.schedule1}</p>
                          <p>{infos.schedule2}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="footer__info-item col-lg-6  col-md-6 col-sm-7">
                <div className="text-content_2">
                  <div className="row first_item">
                    <div className="col-md-12">
                      <div className="items d-flex">
                        <i className="fa-regular fa-envelope"></i>
                        <div className="item__texts">
                          <p>
                            <Link
                              to={
                                "/" + (language ? language : "ru") + "/contacts"
                              }
                            >
                              {infos.email1}
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row second_item">
                    <div className="col-md-12">
                      <div className="items d-flex">
                        <a href="#location">
                          <i className="fa-solid fa-location-dot"></i>
                        </a>
                        <div className="item__texts">
                          <p>
                            {infos.country}, {infos.city},
                          </p>
                          <p>
                            {infos.district}, {infos.street}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__middle">
            <p className="text-center">
              {language == "ru"
                ? `Информация и фотоконтент на сайте защищены. Их нельзя записывать, копировать, 
распространять, иначе возникнет соблазн их сохранить!`
                : language == "uz"
                ? `Saytdagi ma'lumotlar va fotosuratlar himoyalangan. Ular yozib olinmaydi, ko'chirilmaydi,
tarqatilmaydi, aks holda sizga nisbatan jiddiy choralar ko’riladi!`
                : `Information and photo content on the site are protected. They may not be recorded, copied,
distribute, otherwise you will be tempted to keep them!`}
            </p>
          </div>
          <div className="footer__bottom">
            <p>
              © Copyright 2022 D.Q.T. Все права защищены!{" "}
              <a href="http://rdevs.uz/ccslife" className="text-black">
                Разработчики
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
