import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { readMore } from "../TextsLanguages";
import "./article.css";
import axios from "axios";
import { Basic_Url } from "../BasicUrl";

function Article() {
  const { language = "ru" } = useParams();

  /* Texts Languages */
  const readMoreText = readMore.filter((item) => Object.keys(item) == language);
  /* /Texts Languages */

  const [banner, setBanner] = useState([]);

  useEffect(() => {
    getBanner();
  }, []);
  useEffect(() => {
    getBanner();
  }, [language]);
  async function getBanner() {
    const data = await axios
      .get(Basic_Url + "/api/" + (language ? language : "ru") + "/banner/")
      .then((res) => res.data);
    setBanner(data[0]);
  }
  return (
    <>
      <article className="article__content-container">
        <div className="article__content">
          <h3>{banner.title}</h3>
          <p>{banner.text}</p>
          <button className="btn btn-outline-info" type="button">
            <Link
              className="nav-link"
              to={"/" + (language ? language : "ru") + "/about"}
            >
              {Object.values(readMoreText[0]).join()}
            </Link>
          </button>
        </div>
      </article>
    </>
  );
}

export default Article;
